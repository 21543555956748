import React from "react";
import "./../App.css";

const NotFound = () => {
  return (
    <div className="msgContainer">
      <h1 className="errorHeader">404</h1>
      <p className="msg">Oops! The page you're looking for doesn't exist.</p>
    </div>
  );
};

export default NotFound;
