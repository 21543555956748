import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


function Home() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
      const location = useLocation();
    
      useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const name = searchParams.get("name");
        const application_id = searchParams.get("application_id");
        const token = searchParams.get("token");
    
        if (name && application_id && token) {
          setUserData({
            name: name,
            application_id: application_id,
            token: token
          });
        } else {
            setUserData(null);
        }
      }, [location.search]);
    
      useEffect(() => {
        if (userData === null) {
            sessionStorage.clear();
            navigate('/error');
        }
        if (userData && userData.name && userData.application_id && userData.token) {
            sessionStorage.setItem("user_data", JSON.stringify(userData));
            navigate('/allow-permission', {state: userData});
        }
      }, [userData, navigate]);

      
    return <>
        Redirecting...
    </>
}


export default Home;