import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./../App.css";
import axios from "axios";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";

const DigilockerDocument = () => {
  const [code_id, setCodeId] = useState("");
  const [document, setDocument] = useState(null);
  const [userData, setUserData] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const _userData = JSON.parse(sessionStorage.getItem("user_data"));
    setUserData({
      ..._userData
    });
    const code = searchParams.get("code");
    if (code) {
      setCodeId(code);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    console.log('HELLO', code_id, userData)
    // ?code=${code_id}&application_id=${userData?.application_id}
    // process.env.REACT_APP_API_ENDPOINT
    
    if (code_id && userData.token && userData.application_id) {
      const getDocument = async () => {
        try {
          const {data: response} = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/v1/listener`,{
              code_id: code_id,
              ...userData
            }
          );
          setDocument(response);
          sessionStorage.removeItem("user_data");
        } catch (error) {
          console.log("error in document save", error.message);
          sessionStorage.removeItem("user_data");
        }
      };
      getDocument();
    }
  }, [code_id, userData]);
  return (
    <div className="container">
      {document ? (
        <>
          {document?.status ? (
            <>
              <div style={{ display: "flex" }}>
                <span className="iconSpan">
                  <IoMdCheckmarkCircleOutline color="#4caf50" size="45px" />
                </span>
                <h1 className="sucessHeader">Success!</h1>
              </div>
              <p className="msg">
                Your documents has been successfully fetched.
              </p>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <span className="iconSpan">
                  <MdErrorOutline color="#ff6b6b" size="45px" />
                </span>
                <h1 className="errorHeader">Something went wrong</h1>
              </div>
              <p className="msg">Please contact Virtual Admission System to reprocess.</p>
            </>
          )}
        </>
      ) : (
        <p className="msg">Fetching data...</p>
      )}
    </div>
  );
};

export default DigilockerDocument;