import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
function AllowPermission() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});

  const handleConsent = () => {
    // ZiXqEAZS72y8u2efZj3VV54E_0GfEQfsWnoxygIshD4
    // https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=KVF48840D7&state=oidc_flow&redirect_uri=https%3A%2F%2Findia.verify.vasuniverse.com%2Fdigilocker&code_challenge=ZiXqEAZS72y8u2efZj3VV54E_0GfEQfsWnoxygIshD4&code_challenge_method=S256&dl_flow=signin&pla=Y
    // https://digilocker.meripehchaan.gov.in/signin/oauth_partner/%252Foauth2%252F1%252Fconsent%253Flogo%253D%2526pla%253DY%2526response_type%253Dcode%2526client_id%253DKVF48840D7%2526state%253Doidc_flow%2526redirect_uri%253Dhttps%25253A%25252F%25252Findia.verify.vasuniverse.com%25252Fdigilocker%2526code_challenge%253Dhttps%25253A%25252F%25252Findia.verify.vasuniverse.com%25252Fdigilocker%2526code_challenge_method%253DS256%2526orgid%253D061500%2526txn%253D66e2eb26be732oauth21726147366%2526hashkey%253D30c0ee4e3edea50ca0c36ad8d364d0b23a81a760c2cd1f2086164b6ca5ffd7b6%2526requst_pdf%253DY%2526app_name%253DVkFTIERldg%25253D%25253D%2526signup%253Dsignup
    // const url = `${process.env.REACT_APP_DIGILOKER_API}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&state=oidc_flow&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URL)}&code_challenge=${process.env.REACT_APP_REDIRECT_URL}&code_challenge_method=S256&dl_flow=signin&pla=Y`;
    window.location.href = 'https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=KVF48840D7&state=oidc_flow&redirect_uri=https%3A%2F%2Findia.verify.vasuniverse.com%2Fdigilocker&code_challenge=ZiXqEAZS72y8u2efZj3VV54E_0GfEQfsWnoxygIshD4&code_challenge_method=S256&dl_flow=signin&pla=Y';
  };
  
  useEffect(() => {
    try {
      const {name, application_id, token} = JSON.parse(sessionStorage.getItem("user_data"));
      console.log(name, application_id, token)
      setUserData({
        name: name,
        application_id: application_id,
        token: token
      })
    } catch(e) {
      sessionStorage.clear();
      navigate('/error'); 
    }
    
    // const searchParams = new URLSearchParams(location.search);
    // const user_name = searchParams.get("user_name");
    // const application_id = searchParams.get("application_id");
    // const token = searchParams.get("token");
    // if (user_name && application_id) {
    //   setUserData({
    //     user_name: user_name,
    //     application_id: application_id,
    //     token: token
    //   });
    // }
  }, [navigate]);

  // useEffect(() => {
  //   if (userData.user_name && userData.application_id) {
  //     localStorage.setItem("user_data", JSON.stringify(userData));
  //   }
  // }, [userData]);

  

  return (
    <div className="container">
      <div className="header">
        <img
          src={
            "https://www.digilocker.gov.in/assets/img/digilocker_logo_new.png"
          }
          className="App-logo"
          alt="logo"
        />

        <h2>Grant Access to Your Documents</h2>
      </div>
      <div className="content">
        <h3>Virtual Admission System is requesting the following access:</h3>
        <ul className="permissions">
          <li>Access to your education documents</li>
        </ul>
        <h4>Name: {userData?.name}</h4>
        <p>
          Please review the permissions above. By granting access, you allow
          Virtual Admission System to retrieve and verify your documents from DigiLocker as
          described.
        </p>
      </div>
      <div className="actions">
        <button className="allow" onClick={handleConsent}>
          Allow
        </button>
        <button className="deny">Deny</button>
      </div>
    </div>
  );
}

export default AllowPermission;
